import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SecurityService } from "../service/security.service";
import { AlertController, LoadingController } from "@ionic/angular";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  passwordForm: FormGroup;

  showPass: boolean;

  invalidCode: boolean;

  constructor(private fb: FormBuilder, private route: ActivatedRoute,
              private securityService: SecurityService, private loadingCtrl: LoadingController,
              private alerter: AlertController, private router: Router) {}

  ngOnInit() {
    this.passwordForm = this.fb.group({
      code: [ '', Validators.required ],
      password: [ '', [ Validators.required, Validators.minLength(8) ] ]
    });
  }

  async ionViewWillEnter() {
    this.invalidCode = false;
    this.route.params.subscribe(params => {
      this.passwordForm.patchValue({
        code: params.code
      });
      this.securityService.isValidNewUser(params.code).then(res => {
        if (res !== '1') {
          this.invalidCode = true;
          this.passwordForm.get('password').disable();
        }
      });
    });
  }

  async changePass() {
    const loader = await this.loadingCtrl.create({
      message: 'Changing password...',
      showBackdrop: true,
      backdropDismiss: false
    });
    await loader.present();
    let request = this.passwordForm.value;
    request.password = btoa(request.password);
    let res = await this.securityService.activate(request);
    let alert;
    if (res.code === 100) {
      alert = await this.alerter.create({
        header: 'Success',
        backdropDismiss: false,
        message: 'Password changed successfully.',
        buttons: [{
          text: 'OK',
          handler: () => {
            this.router.navigate([ '/login' ]).then();
          }
        }]
      });
    }
    else {
      alert = await this.alerter.create({
        header: 'Failure',
        backdropDismiss: false,
        message: 'Failed to change password. Please contact your Administrator.',
        buttons: [{
          text: 'OK',
          role: 'cancel'
        }]
      });
    }
    await loader.dismiss().then(() => alert.present());
  }

}
