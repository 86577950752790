import { Injectable } from '@angular/core';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserRepository {

  private key = '__USERS__';

  async save(user: User) {
    const value = JSON.stringify(user);
    localStorage.setItem(this.key, value);
  }

  async get(): Promise<User> {
    const value = localStorage.getItem(this.key);
    return JSON.parse(value) as User;
  }

  async remove() {
    localStorage.removeItem(this.key);
  }
}
