import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NotFoundComponent } from './not-found/not-found.component';
import { LogoutComponent } from './logout/logout.component';
import { HttpInterceptorService } from './service/http-interceptor.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { SharedModule } from './shared/shared.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MsalBroadcastService, MsalGuard, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Drivers } from '@ionic/storage';
import { StompJsService } from './service/stomp-js-service';

export const momentAdapterFactory = () => adapterFactory(moment);

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const msalConfig = {
  auth: {
    clientId: '428f5516-3998-4d28-9ce4-d8be5a8e2976',
    authority: 'https://login.microsoftonline.com/afnexus.onmicrosoft.com',
    redirectUri: 'https://flexiict-uat.swiftoffice.org',
    knownAuthorities: [ 'https://flexiict-uat.swiftoffice.org' ]
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE
  },
  system: {
    loggerOptions: {loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Trace:
            break;
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }, logLevel: LogLevel.Verbose, piiLoggingEnabled: false}
  }
} as Configuration;

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      driverOrder: [ Drivers.LocalStorage ]
    }),
    AppRoutingModule,
    NgxDatatableModule,
    DragAndDropModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    SharedModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    MsalModule.forRoot(new PublicClientApplication(msalConfig), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [ 'user.read', 'openid', 'profile', 'email' ],
        prompt: 'select_account'
      }
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([])
    })
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    MsalService, MsalGuard, MsalBroadcastService,
    {
      provide: StompJsService,
      useFactory: () => {
        const svc = new StompJsService();
        svc.connect();
        return svc;
      }
    }
  ],
  bootstrap: [ AppComponent, MsalRedirectComponent ],
})
export class AppModule {
}
