import { Injectable } from '@angular/core';
import { SecurityService } from './security.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  private public = [
      '/users/activate'
  ];

  private common = [
    '/logout',
    '/home',
    '/home/icts',
    '/users/profile'
  ];

  private permissions = {
    // add more urls in each role as needed
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SERVICEMEN: [ '/bookings', '/bookings/details' ],

    // eslint-disable-next-line @typescript-eslint/naming-convention
    SCHEDULER: [
      '/scheduler/trainees',
      '/scheduler/form',
      '/scheduler/upload',
      '/scheduler/duplicate',
      '/scheduler/history'
    ],

    // eslint-disable-next-line @typescript-eslint/naming-convention
    ADMIN: [
      '/admin/trainees',
      '/admin/history',
      '/scheduler/form'
    ]
  };

  constructor(private securityService: SecurityService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = await this.securityService.getCurrentUser();
    if (user) {
      await this.securityService.trackSession();
      const permittedRoutes: [] = this.permissions[user.role].concat(this.common);
      return permittedRoutes.some(pr => state.url.indexOf(pr) >= 0);
    }
    else {
      const isPublicRoute = this.public.some(pr => state.url.indexOf(pr) >= 0);
      if (isPublicRoute) {
        return true;
      }
    }
    await this.router.navigate([ 'login' ]);
    return false;
  }
}
