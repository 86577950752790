import { Client, IFrame, Message, StompSubscription } from '@stomp/stompjs';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';

@Injectable({
  providedIn: 'root'
})
export class StompJsService {

  onConnect$ = new Subject<IFrame>();

  onDisconnect$ = new Subject<void>();

  private client?: Client;

  connect() {
    console.info('Connecting to WebSocket...');
    if (!this.client) {
      this.client = new Client({
        brokerURL: environment.socketsUrl,
        debug: msg => {
          if (!environment.production) {
            console.debug(new Date(), msg);
          }
        },
        reconnectDelay: 500,
        heartbeatOutgoing: 1000,
        heartbeatIncoming: 1000
      });
      if (typeof WebSocket === 'function') {
        this.client.webSocketFactory = () => new SockJS(environment.sockJsUrl);
      }
      this.client.onConnect = frame => {
        console.info('Client connected.');
        this.onConnect$.next(frame);
      };
      this.client.onDisconnect = () => {
        console.warn('Client disconnected.');
        this.onDisconnect$.next();
      };
      this.client.onWebSocketClose = event => {
        console.warn(`Websocket closed due to reason: ${event.reason}`);
      };
      this.client.onStompError = frame => {
        // Will be invoked in case of error encountered at Broker
        // Bad login/passcode typically will cause an error
        // Complaint brokers will set `message` header with a brief message. Body may contain details.
        // Compliant brokers will terminate the connection after any error
        console.error('Broker reported error: ' + frame.headers['message']);
        console.error('Additional details: ' + frame.body);
      };
      this.client.activate();
      console.info('WebSocket connection established.');
    }
  }

  async disconnect(): Promise<void> {
    await this.client?.deactivate();
  }

  subscribe(destination: string, callback: (msg: Message) => void): StompSubscription | null {
    return this.client?.subscribe(destination, callback);
  }
}
