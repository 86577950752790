import { Injectable } from '@angular/core';
import { SecurityService } from './security.service';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Notification } from '../model/notification';
import { HttpClient } from '@angular/common/http';
import { NotificationsResponse } from '../model/notifications-response';
import { Message, StompSubscription } from '@stomp/stompjs';
import { StompJsService } from './stomp-js-service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notification$ = new Subject<Notification>();

  state$ = new BehaviorSubject<number>(0);

  private subscription?: StompSubscription;

  constructor(
      private securityService: SecurityService,
      private http: HttpClient,
      private stompService: StompJsService
  ) {}

  async connect() {
    this.securityService.user$.subscribe({
      next: user => {
        if (user) {
          console.info('User authenticated. Establishing notification service connection...');
          this.stompService.onConnect$.subscribe({
            next: () => {
              this.subscription = this.stompService.subscribe(`/queue/notifications/${user.subject}`, (msg: Message) => {
                const notification = JSON.parse(msg.body) as Notification;
                this.notification$.next(notification);
                msg.ack();
              });
              console.info('Notification service connection established.');
            }
          });
        }
      }
    });
  }

  async disconnect(): Promise<void> {
    this.subscription?.unsubscribe();
    await this.stompService.disconnect();
    this.state$.next(0);
  }

  async getLatestNotifs(): Promise<NotificationsResponse> {
    try {
      return await this.http.get<NotificationsResponse>(`${environment.apiUrl}/notifications`).toPromise();
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        } ]
      };
    }
  }

  async markAsRead(notifId: number): Promise<void> {
    try {
      await this.http.post<any>(`${environment.apiUrl}/notifications/read/${notifId}`, {}).toPromise();
    }
    catch (err) {
      console.error({
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        } ]
      });
    }
  }

  async markAllAsRead(): Promise<void> {
    try {
      await this.http.post<any>(`${environment.apiUrl}/notifications/read-all`, {}).toPromise();
    }
    catch (err) {
      console.error({
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        } ]
      });
    }
  }
}
