import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { CREDS, ID_TOKEN } from './security.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private storage: Storage) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.production) {
      return fromPromise(this.storage.get(ID_TOKEN)).pipe(
        switchMap(token => {
          if (token) {
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }
          return next.handle(req);
        })
      );
    }
    else {
      return fromPromise(this.storage.get(CREDS)).pipe(
        switchMap(c => {
          if (c) {
            req = req.clone({
              setHeaders: {
                Authorization: `Basic ${c}`,
                rejectUnauthorized: 'false'
              }
            });
          }
          return next.handle(req);
        })
      );
    }
  }
}
