import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import { environment } from '../../environments/environment';
import { UserResponse } from '../model/user-response';
import { User, UserStatus } from '../model/user';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserRequest } from '../model/user-request';
import { BaseResponse } from '../model/base-response';
import { ActivationRequest } from '../model/activation-request';
import { UserSession } from '../model/user-session';
import * as moment from 'moment';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { AccountInfo, AuthenticationResult, EventType, InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { UserRepository } from './user.repository';

export const USER_KEY = '__user__';
export const CREDS = '__creds__';
export const AD_TOKEN = '__ad_token__';
export const ID_TOKEN = '__id_token__';
export const SESSION_KEY = '__s__';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  authenticated$ = new BehaviorSubject<number>(0);

  user$ = new BehaviorSubject<User>(null);

  logout$ = new Subject<boolean>();

  private credsStore: Storage;

  private timeout?: number;

  constructor(private http: HttpClient, private storage: Storage, private msalService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private userRepo: UserRepository) {}

  async init(): Promise<void> {
    await this.initStore();
    if (environment.production) {
      const currentUser = await this.userRepo.get();
      this.user$.next(currentUser);
      this.msalBroadcastService.msalSubject$.pipe(
        filter(msg => msg.eventType === EventType.LOGIN_FAILURE)
      ).subscribe(() => {
        this.authenticated$.next(0);
      });
      this.authenticated$.next(0);
      this.msalBroadcastService.inProgress$.pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      ).subscribe({
        next: () => {
          const msalService = this.msalService.instance;
          const activeAccount = msalService.getActiveAccount();
          const allAccounts = msalService.getAllAccounts();
          if (!activeAccount && allAccounts.length > 0) {
            msalService.setActiveAccount(allAccounts[0]);
          }
        }
      });
    }
    else {
      const u = (await this.storage.get(USER_KEY)) as User;
      if (u) {
        const session: UserSession = await this.credsStore.get(SESSION_KEY);
        if (!session || !session.lastLogin) {
          await this.logout(false);
        }
        else if (session.lastLogin && moment(session.lastLogin).diff(moment(), 'seconds') >= environment.sessionTimeout) {
          await this.logout(false);
        }
        else {
          await this.trackSession();
          this.user$.next(u);
        }
      }
    }
  }

  async initStore() {
    if (!this.credsStore) {
      this.credsStore = await this.storage.create();
    }
  }

  async login(username: string, password: string): Promise<UserResponse> {
    const credentials = btoa(`${username}:${password}`);
    try {
      await this.credsStore.set(CREDS, credentials);
      const res = await this.http.post<UserResponse>(`${environment.apiUrl}/login`, {
        username,
        password
      }, {
        observe: 'response'
      }).toPromise();
      const body = res.body;
      if (body.code === 100) {
        await this.credsStore.set(USER_KEY, body.data);
        await this.credsStore.set(SESSION_KEY, {
          username,
          lastLogin: new Date()
        } as UserSession);
        await this.trackSession();
        this.user$.next(body.data);
      }
      else {
        await this.logout(false);
      }
      return body;
    }
    catch (error) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [{
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        }]
      };
    }
  }

  async adLogin() {
    let result;
    try {
      if (this.msalGuardConfig.authRequest) {
        result = await this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest).toPromise();
      }
      else {
        result = await this.msalService.loginPopup().toPromise();
      }
    }
    catch (e) {
      console.error(`Failed to authenticate the user: ${e.message}`);
    }
    if (result) {
      await this.handleAuthResult(result);
      await this.linkAuth(result);
      await this.handleActiveAccount(result.account);
    }
    else {
      this.authenticated$.next(0);
    }
  }

  async clearStorage() {
    await this.credsStore.remove(CREDS);
    await this.credsStore.remove(USER_KEY);
  }

  async logout(sessionTimeout: boolean): Promise<void> {
    await this.clearStorage();
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.user$.next(null);
    this.authenticated$.next(0);
    this.logout$.next(sessionTimeout);
  }

  async isLoggedIn(): Promise<boolean> {
    const user = await this.getCurrentUser();
    return !!user;
  }

  getCurrentUser(): Promise<User> {
    return new Promise<User>(resolve => {
      if (environment.production) {
        resolve(this.userRepo.get());
      }
      else {
        this.initStore().then(() => this.credsStore.get(USER_KEY)).then(c => resolve(c));
      }
    });
  }

  async deleteUser(user: User): Promise<void> {
    try {
      await this.http.post<void>(`${environment.apiUrl}/users/delete/${user.id}`, {}).toPromise();
    }
    catch (err) {
      console.error('Unable to delete/inactivate user.');
    }
  }

  async getUser(username: string): Promise<UserResponse> {
    try {
      const url = `${environment.apiUrl}/users/${btoa(username)}`;
      return await this.http.get<UserResponse>(url).toPromise();
    }
    catch (err) {
      console.error(err);
      return {
        code: 999,
        status: 'FAILED',
        errors: [{
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        }]
      };
    }
  }

  async addUser(role: string, request: UserRequest): Promise<BaseResponse<any>> {
    try {
      return await this.http.post(`${environment.apiUrl}/users/${role}`, request).toPromise().then(() => ({
          code: 100,
          status: 'SUCCESS',
          errors: null
        }));
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [{
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        }]
      };
    }
  }

  async isValidNewUser(code: string): Promise<string> {
    try {
      const res = await this.http.get<string>(`${environment.apiUrl}/users/validate/${code}`, {
        observe: 'response'
      }).toPromise();
      return res.body + '';
    }
    catch (err) {
      return '0';
    }
  }

  async activate(req: ActivationRequest): Promise<UserResponse> {
    try {
      return await this.http.post<UserResponse>(`${environment.apiUrl}/users/activate`, req).toPromise();
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [{
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        }]
      };
    }
  }

  async updateEmail(email: string): Promise<BaseResponse<any>> {
    const user = await this.getCurrentUser();
    try {
      await this.http.put(`${environment.apiUrl}/users/update-email`, {
        callSign: user.username,
        email
      }).toPromise();
      user.email = email;
      await this.credsStore.set(USER_KEY, user);
      return {
        code: 100,
        status: 'SUCCESS',
        errors: null
      };
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [{
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        }]
      };
    }
  }

  async changePassword(pass: string): Promise<BaseResponse<any>> {
    const user = await this.getCurrentUser();
    try {
      const req = {
        callSign: user.username,
        credential: btoa(pass)
      };
      await this.http.put(`${environment.apiUrl}/users/change-password`, req).toPromise();
      return {
        code: 100,
        status: 'SUCCESS',
        errors: null
      };
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [{
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        }]
      };
    }
  }

  async resetPassword(email: string): Promise<BaseResponse<any>> {
    try {
      const res = await this.http.put(`${environment.apiUrl}/users/reset-password/${email}`, {}, { observe: 'response' }).toPromise();
      if (res.body) {
        return res.body as BaseResponse<any>;
      }
      else {
        return {
          code: 100,
          status: 'SUCCESS'
        };
      }
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [{
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        }]
      };
    }
  }

  async trackSession() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(async () => {
      await this.logout(true);
    }, environment.sessionTimeout * 1000);
  }

  private async handleAuthResult(result: AuthenticationResult) {
    await this.credsStore.set(AD_TOKEN, result.accessToken);
    await this.credsStore.set(ID_TOKEN, result.idToken);
  }

  private async linkAuth(result: AuthenticationResult) {
    const subject = result.idTokenClaims['sub'];
    const request = {
      username: result.account.username,
      subject,
      accessToken: result.accessToken,
      idToken: result.idToken
    };
    try {
      const res = (await this.http.post<UserResponse>(`${environment.apiUrl}/login`, request).toPromise()).data;
      if (subject !== res.subject) {
        const reqUpdate = {
          id: res.id,
          subject
        };
        await this.http.post<void>(`${environment.apiUrl}/login/update`, reqUpdate).toPromise();
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  private async handleActiveAccount(activeAccount: AccountInfo) {
    const res = await this.getUser(activeAccount.username);
    // valid user
    if (res.code === 100) {
      const user = res.data;
      await this.userRepo.save(res.data);
      this.user$.next(res.data);
      if (UserStatus.ACTIVE === user.status) {
        this.authenticated$.next(1);
      }
      else {
        this.authenticated$.next(2);
      }
    }
    // unregistered user
    else if (res.code === 404) {
      this.authenticated$.next(-1);
      this.user$.next({
        id: 0,
        username: '',
        status: UserStatus.UNREG,
        dateRegistered: new Date(),
        email: '',
        role: '',
        mobileNo: '',
        subject: ''
      });
    }
    else if (res.code === 999) {
      this.authenticated$.next(3);
    }
    // user status not active
    else {
      this.user$.next(res.data);
      this.authenticated$.next(2);
    }
  }
}
