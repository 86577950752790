import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'ict-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  year = moment().format('YYYY');

  constructor() { }

  ngOnInit() {}

}
