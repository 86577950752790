import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SecurityService } from "../service/security.service";
import { AlertController } from "@ionic/angular";
import { Router } from "@angular/router";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  cpForm: FormGroup;

  state: number;

  constructor(private fb: FormBuilder, private securityService: SecurityService,
              private alerter: AlertController, private router: Router) {}

  ngOnInit() {
    this.cpForm = this.fb.group({
      email: [ '', [ Validators.required, Validators.email ] ]
    });
    this.securityService.clearStorage().then();
  }

  ionViewWillEnter() {
    this.cpForm.reset();
    this.state = 0;
  }

  async resetPassword() {
    this.state = 1;
    let res = await this.securityService.resetPassword(this.cpForm.value.email);
    if (res.code === 100) {
      let alert = await this.alerter.create({
        header: 'Success',
        message: 'A password reset link has been sent to your email',
        backdropDismiss: false,
        translucent: true,
        buttons: [{
          text: 'OK',
          handler: async () => {
            await this.router.navigate([ '/login' ]);
          }
        }]
      });
      await alert.present();
      this.state = 2;
    }
    else {
      let alert = await this.alerter.create({
        header: 'Failed Request',
        message: res.errors[0].desc,
        buttons: [ 'OK' ]
      });
      await alert.present();
      this.state = 0;
    }
  }

}
