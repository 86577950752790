import { Component, NgZone, OnInit } from '@angular/core';
import { NotificationService } from '../../service/notification.service';
import { Notification, NotifType } from '../../model/notification';
import * as moment from 'moment';

@Component({
  selector: 'ict-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {

  dt = moment;

  notifications: Notification[];

  constructor(
      private notifService: NotificationService,
      private zone: NgZone
  ) {}

  async ngOnInit() {
    await this.notifService.connect();
    await this.refresh();
    this.notifService.notification$.subscribe(n => {
      n.data = JSON.parse(n.data);
      this.zone.run(() => {
        this.notifications.unshift(n);
      });
    });
  }

  async refresh() {
    const res = await this.notifService.getLatestNotifs();
    this.notifications = res.data.map(n => {
      n.data = JSON.parse(n.data);
      return n;
    });
  }

  async markAsRead(n: Notification) {
    await this.notifService.markAsRead(n.id);
    n.read = true;
  }

  async markAllAsRead() {
    await this.notifService.markAllAsRead().then(() => this.refresh());
  }
}
