import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../../service/security.service';
import { BehaviorSubject } from 'rxjs';
import { Platform, PopoverController } from '@ionic/angular';
import { debounceTime } from 'rxjs/operators';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { Router } from '@angular/router';

@Component({
  selector: 'ict-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  name$ = new BehaviorSubject<string>('Guest');

  buttonSize;

  constructor(private securityService: SecurityService, private platform: Platform,
              private popoverCtrl: PopoverController, private router: Router) {}

  async ngOnInit() {
    setTimeout(() => {
      this.checkSize();
    }, 100);
    this.platform.resize.pipe(debounceTime(100)).subscribe(() => {
      this.checkSize();
    });
    this.securityService.user$.subscribe(user => {
      this.name$.next(user?.username || 'Guest');
    });
  }

  async showMenu(event) {
    const user = await this.securityService.getCurrentUser();
    if (!user) {
      await this.router.navigate([ '/login' ]);
    }
    else {
      const popupMenu = await this.popoverCtrl.create({
        component: UserMenuComponent,
        showBackdrop: false,
        backdropDismiss: true,
        translucent: true,
        animated: true,
        event
      });
      await popupMenu.present();
    }
  }

  private checkSize() {
    const width: number = this.platform.width();
    if (width <= 576) {
      this.buttonSize = 'small';
    }
    else {
      this.buttonSize = undefined;
    }
  }

}
