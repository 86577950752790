export interface User {
  id: number;
  username: string;
  mobileNo: string;
  status: UserStatus;
  role: string;
  email: string;
  dateRegistered: Date;
  subject: string;
}

export enum UserStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  REMOVED = 'REMOVED',
  UNREG = 'UNREG'
}
