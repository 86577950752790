import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { FooterComponent } from './footer/footer.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { RouterModule } from '@angular/router';
import { AccordionComponent } from './accordion/accordion.component';

@NgModule({
  imports: [ CommonModule, RouterModule, IonicModule ],
  declarations: [ HeaderComponent, FooterComponent, UserMenuComponent, NotificationsComponent, AccordionComponent ],
  exports: [ HeaderComponent, FooterComponent, UserMenuComponent, NotificationsComponent, AccordionComponent ]
})
export class SharedModule {}
