import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './service/auth-guard.service';
import { LogoutComponent } from './logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset/:code',
    component: ResetPasswordComponent
  },
  {
    path: 'home',
    canActivate: [ AuthGuardService ],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [ AuthGuardService ]
  }, {
    path: 'bookings',
    canActivate: [ AuthGuardService ],
    loadChildren: () => import('./booking/booking.module').then(m => m.BookingPageModule)
  },
  {
    path: 'bookings/:id',
    canActivate: [ AuthGuardService ],
    loadChildren: () => import('./booking/booking.module').then(m => m.BookingPageModule)
  },
  {
    path: 'scheduler',
    canActivate: [ AuthGuardService ],
    loadChildren: () => import('./scheduler/scheduler.module').then(m => m.SchedulerPageModule)
  },
  {
    path: 'admin',
    canActivate: [ AuthGuardService ],
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule)
  },
  {
    path: 'payments',
    canActivate: [ AuthGuardService ],
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsPageModule)
  },
  {
    path: 'users',
    canActivate: [ AuthGuardService ],
    loadChildren: () => import('./users/users.module').then(m => m.UsersPageModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: !isIframe ? 'enabled' : 'disabled'
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
