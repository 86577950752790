import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from '../service/security.service';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router, private securityService: SecurityService,
              private loader: LoadingController) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    const loading = await this.loader.create({
      translucent: true,
      backdropDismiss: false,
      message: 'Logging you out...'
    });
    await this.securityService.logout(false);
    await loading.dismiss();
    await this.router.navigate([ '/login' ]);
  }

}
