import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SecurityService } from './service/security.service';
import { HTMLIonOverlayElement } from '@ionic/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [ 'app.component.scss' ],
})
export class AppComponent implements OnInit {

  count = new BehaviorSubject<number>(0);

  constructor(private securityService: SecurityService, private router: Router) {}

  async ngOnInit() {
    await this.securityService.init();
    this.securityService.logout$.subscribe(async sessionTimeout => {
      let extras = {};
      if (sessionTimeout) {
        extras = {
          queryParams: { st: 1 },
          replaceUrl: true
        };
      }
      this.closeAll();
      await this.router.navigate([ '/login' ], extras);
    });
  }

  private closeAll() {
    // adjust selector to fit your needs
    const overlays = document.querySelectorAll('ion-alert, ion-action-sheet, ion-loading, ion-modal, ion-picker, ion-popover, ion-toast');
    const overlaysArr = Array.from(overlays) as HTMLIonOverlayElement[];
    overlaysArr.forEach(o => o.dismiss());
  };
}
