import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

  constructor(private router: Router, private popoverCtrl: PopoverController) { }

  ngOnInit() {}

  async route(url: string) {
    await this.popoverCtrl.dismiss();
    await this.router.navigate([ url ]);
  }

}
